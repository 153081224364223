import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { RidesComponent } from './rides/rides.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehicleaddformComponent } from './vehicleaddform/vehicleaddform.component';
import { VehiclelistComponent } from './vehiclelist/vehiclelist.component';
import { FormsModule } from '@angular/forms';
import { RideslistComponent } from './rideslist/rideslist.component';
import { RideaddformComponent } from './rideaddform/rideaddform.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RidesComponent,
    VehiclesComponent,
    VehicleaddformComponent,
    VehiclelistComponent,
    RideslistComponent,
    RideaddformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
