<table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Make</th>
        <th scope="col">Model</th>
        <th scope="col" (click)="sort()"><button class="btn fw-bold btn-light">License plate</button></th>
        <th scope="col">Type</th>
        <th scope="col">Gas Usage</th>
        <th scope="col">Price</th>
        <th scope="col">Odometer</th>
        <th scope="col">Passengers</th>
        <th scope="col">Cargo weight</th>
        <th scope="col">Cargo volume</th>
        <th scope="col">In use</th>
        <th scope="col">Edit</th>
        <th scope="col">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let vehicle of vehicles">
        <th scope="row">{{vehicle.id}}</th>
        <td>{{vehicle.make}}</td>
        <td>{{vehicle.model}}</td>
        <td>{{vehicle.licensePlate}}</td>
        <td *ngIf="vehicle.type===0">Car</td>
        <td *ngIf="vehicle.type===1">Van</td>
        <td *ngIf="vehicle.type===2">Freighter</td>
        <td>{{vehicle.gasUsage}} l/100km</td>
        <td>{{vehicle.pricePerKM.toFixed(2)}}€/km</td>
        <td>{{vehicle.odometer}} km</td>
        <td>{{vehicle.vehicleOptions.maxPassengers}} people</td>
        <td>{{vehicle.vehicleOptions.maxCargoWeight}}kg</td>
        <td>{{vehicle.vehicleOptions.maxCargoVolume}}m³</td>
        <td><i *ngIf="vehicle.inUse" class="bi bi-check-lg text-danger ms-3"></i>
          <i *ngIf="!vehicle.inUse" class="bi bi-x-lg text-primary ms-3"></i></td>
        <td>
          <button type="button" class="btn btn-sm mx-1 btn-info" (click)="emitEdit(vehicle)"><i class="bi bi-pencil text-white"></i></button>
        </td>
        <td>
          <button type="button" class="btn btn-sm mx-1 btn-danger" (click)="deleteVehicleByID(vehicle.id)"><i class="bi bi-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-sm btn-danger " (click)="deleteAllVehicles()">Delete all vehicles</button>
  </div>