<div class="container col-7">
    <div class="p-5 mb-4 bg-light rounded-3">
        <div class="row d-flex justify-content-between">
            <div class="col-12"><h2 class="mb-4 fw-bold text-center">Add a new ride...</h2></div>
        </div>
        <form class="row g-3">
            <div class="col-md-4">
                <label for="validationFrom" class="form-label">From</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': fromValid, 'is-invalid': !fromValid}" (change)="validateFrom()" id="validationFrom" required="" data-cip-id="validationFrom" [(ngModel)]="from" name="from">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">IATA</small></span>
                    <div *ngIf="fromValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!fromValid" class="invalid-feedback">
                        Please provide valid IATA code.
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label for="validationTo" class="form-label">To</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': toValid, 'is-invalid': !toValid}" (change)="validateTo()" id="validationTo" required="" data-cip-id="validationTo" [(ngModel)]="to" name="to">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">IATA</small></span>
                    <div *ngIf="toValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!toValid" class="invalid-feedback">
                        Please provide valid IATA code.
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label for="validationDistance" class="form-label">Distance</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': distanceValid, 'is-invalid': !distanceValid}" (change)="validateDistance()" id="validationDistance" required="" data-cip-id="validationDistance" [(ngModel)]="distance" name="distance">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">km</small></span>
                    <div *ngIf="distanceValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!distanceValid" class="invalid-feedback">
                        Please provide valid distance.
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="ValidationType" class="form-label">Type</label>
                <select class="form-select" [ngClass]="{'is-valid': typeValid, 'is-invalid': !typeValid}" (change)="validateType(true)" id="ValidationType" required="" data-cip-id="cIPJQ342845647" [(ngModel)]="type" name="type">
                  <option selected="" disabled="" value="">Choose...</option>
                  <option>Passenger transport</option>
                  <option>Cargo transport</option>
                </select>
                <div *ngIf="typeValid" class="valid-feedback">
                  Looks good!
                </div>
                <div *ngIf="!typeValid" class="invalid-feedback">
                  Please select a valid type.
                </div>
            </div>
            <div class="col-md-6" *ngIf="!typeValid">
                <label for="validationPassengers" class="form-label">Passengers</label>
                <fieldset disabled>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': passenegrsValid, 'is-invalid': !passenegrsValid}" (change)="validatePassengers()" id="validationPassengers" required="" data-cip-id="validationPassengers" [(ngModel)]="passengers" name="passengers">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">people</small></span>
                    <div *ngIf="passenegrsValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!passenegrsValid" class="invalid-feedback">
                        Please provide valid number of passengers.
                    </div>
                </div>
                </fieldset>
            </div>
            <div class="col-md-6" *ngIf="typeValid && type==='Passenger transport'">
                <label for="validationPassengers2" class="form-label">Passengers</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': passenegrsValid, 'is-invalid': !passenegrsValid}" (change)="validatePassengers()" id="validationPassengers2" required="" data-cip-id="validationPassengers2" [(ngModel)]="passengers" name="passengers">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">people</small></span>
                    <div *ngIf="passenegrsValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!passenegrsValid" class="invalid-feedback">
                        Please provide valid number of passengers.
                    </div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="typeValid && type==='Cargo transport'">
                <label for="validationCargoWeight" class="form-label">Cargo weight</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': cargoWeightValid, 'is-invalid': !cargoWeightValid}" (change)="validateCargoWeight()" id="validationCargoWeight" required="" data-cip-id="validationCargoWeight" [(ngModel)]="cargoWeight" name="cargoWeight">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">kg</small></span>
                    <div *ngIf="cargoWeightValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!cargoWeightValid" class="invalid-feedback">
                        Please provide valid cargo weight.
                    </div>
                </div>
            </div>
            <div class="col-md-3" *ngIf="typeValid && type==='Cargo transport'">
                <label for="validationCargoVolume" class="form-label">Cargo volume</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': cargoVolumeValid, 'is-invalid': !cargoVolumeValid}" (change)="validateCargoVolume()" id="validationCargoVolume" required="" data-cip-id="validationCargoVolume" [(ngModel)]="cargoVolume" name="cargoVolume">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">m³</small></span>
                    <div *ngIf="cargoVolumeValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!cargoVolumeValid" class="invalid-feedback">
                        Please provide valid cargo volume.
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <label for="ValidationVehicle" class="form-label">Vehicle</label>
                <select class="form-select" [ngClass]="{'is-valid': selectedVehicleValid, 'is-invalid': !selectedVehicleValid}" (change)="validateVehicle()" id="ValidationVehicle" required="" data-cip-id="ValidationVehicle" [(ngModel)]="selectedVehicle" name="selectedVehicle">
                  <option selected="" value="" *ngIf="possibleVehicles.length != 0">Choose...</option>
                  <option selected="" value="" *ngIf="possibleVehicles.length === 0">Womp womp, none available :/</option>
                  <option *ngFor="let vehicle of possibleVehicles">({{vehicle.id}}) {{vehicle.make}} {{vehicle.model}} {{vehicle.licensePlate}} ({{vehicle.pricePerKM.toFixed(1)}}€/km) ({{vehicle.getTypeAsString()}})</option>
                </select>
                <div *ngIf="selectedVehicleValid" class="valid-feedback">
                  Looks good!
                </div>
                <div *ngIf="!selectedVehicleValid" class="invalid-feedback">
                  Please select a vehicle.
                </div>
            </div>
            <div class="col-md-4">
                <label for="validationBasePrice" class="form-label">Base price</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" [ngClass]="{'is-valid': basePriceValid, 'is-invalid': !basePriceValid}" (change)="validateBasePrice()" id="validationBasePrice" required="" data-cip-id="validationBasePrice" [(ngModel)]="basePrice" name="basePrice">
                    <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">€</small></span>
                    <div *ngIf="basePriceValid" class="valid-feedback">
                        Looks good!
                    </div>
                    <div *ngIf="!basePriceValid" class="invalid-feedback">
                        Please provide valid base price.
                    </div>
                </div>
            </div>
        </form>
        <br>
        <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <div class="col-4"><button type="button" class="btn btn-success" (click)="refresh()">Clear</button></div>
              <div class="col-4 text-center"><button type="button" class="btn btn-primary" (click)="createRide()">Submit</button></div>
              <div class="col-4 text-end"><button type="button" class="btn btn-secondary" (click)="calculatePrice()">Total: {{calculatedPrice.toFixed(2)}}€</button></div>
            </div>
            </div>
    </div>
</div>