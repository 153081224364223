import { Component, OnInit, ViewChild } from '@angular/core';
import { Vehicle } from '../models/Vehicle';
import { VehiclesService } from '../services/vehicles.service';
import { VehicleaddformComponent } from '../vehicleaddform/vehicleaddform.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  vehicles: Vehicle[] = [];
  @ViewChild(VehicleaddformComponent) addFormComponent: VehicleaddformComponent;

  constructor(private vehiclesService: VehiclesService) { }

  addVehicle(): void {}

  ngOnInit(): void {
    this.vehicles = this.vehiclesService.getVehicles();
    console.log(this.vehicles)
  }

  deleteAllVehicles(): void {
    this.vehiclesService.deleteAllVehicles();
  }

  receiveVehicle($event) {
    console.log("VehiclesComponent receiveVehicle() Received:");
    console.log($event);
    this.addFormComponent.fillEdit($event);
  }

}
