import { RideType } from "./RideType";
import { Vehicle } from "./Vehicle";

export class Ride {
    private id: number;
    private from: string;
    private destination: string;
    private startingPrice: number;
    private distance: number;
    private vehicle: Vehicle
    private type: RideType
    private price: number;
    private finished: boolean;
    private passengers: number;
    private cargoWeight: number;
    private cargoVolume: number;
    private startTime: Date;
    private endTime: Date;

    constructor(id: number, from: string, destination: string, startingPrice: number, distance: number, vehicle: Vehicle, type: RideType, passengers: number, cargoWeight: number, cargoVolume: number, savedPrice?: number, startTime?: Date, endTime?: Date) {
        this.id = id;
        this.from = from;
        this.destination = destination;
        this.distance = distance;
        this.vehicle = vehicle;
        this.type = type;
        this.passengers = passengers;
        this.cargoWeight = cargoWeight,
        this.cargoVolume = cargoVolume;

        if (savedPrice != null) {
            this.price = savedPrice;
        } else {
            this.price = vehicle.getPricePerKM() * distance + startingPrice;
            if (this.price < 0) {
                this.price = 0;
            }
        }
        
        if (startTime === undefined) {
            this.startTime = new Date();
        } else {
            this.startTime = startTime
        }
        if (endTime === undefined) {
            this.finished = false;
        } else {
            this.finished = true;
            this.endTime = endTime;
        }
    }

    finishRide() {
        this.endTime = new Date();
        this.finished = true;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getFrom(): string {
        return this.from;
    }

    public setFrom(from: string): void {
        this.from = from;
    }

    public getDestination(): string {
        return this.destination;
    }

    public setDestination(destination: string): void {
        this.destination = destination;
    }

    public getStartingPrice(): number {
        return this.startingPrice;
    }

    public setStartingPrice(startingPrice: number): void {
        this.startingPrice = startingPrice;
    }

    public getDistance(): number {
        return this.distance;
    }

    public setDistance(distance: number): void {
        this.distance = distance;
    }

    public getVehicle(): Vehicle {
        return this.vehicle;
    }

    public setVehicle(vehicle: Vehicle): void {
        this.vehicle = vehicle;
    }

    public getType(): RideType {
        return this.type;
    }

    public setType(type: RideType): void {
        this.type = type;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number): void {
        this.price = price;
    }

    public isFinished(): boolean {
        return this.finished;
    }

    public setFinished(finished: boolean): void {
        this.finished = finished;
    }

    public getPassengers(): number {
        return this.passengers;
    }

    public setPassengers(passengers: number): void {
        this.passengers = passengers;
    }

    public getCargoWeight(): number {
        return this.cargoWeight;
    }

    public setCargoWeight(cargoWeight: number): void {
        this.cargoWeight = cargoWeight;
    }

    public getCargoVolume(): number {
        return this.cargoVolume;
    }

    public setCargoVolume(cargoVolume: number): void {
        this.cargoVolume = cargoVolume;
    }

    public getStartTime(): Date {
        return this.startTime;
    }

    public setStartTime(startTime: Date): void {
        this.startTime = startTime;
    }

    public getEndTime(): Date {
        return this.endTime;
    }

    public setEndTime(endTime: Date): void {
        this.endTime = endTime;
    }
}