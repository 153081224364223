import { Injectable } from '@angular/core';
import { Ride } from '../models/Ride';
import { RideType } from '../models/RideType';
import { Vehicle } from '../models/Vehicle';
import { VehiclesService } from './vehicles.service';

@Injectable({
  providedIn: 'root'
})
export class RidesService {
  private rides: Ride[] = [];

  constructor(private vehiclesService: VehiclesService) { 
    this.rides = this.getRides();
  }

  createRide(from: string, destination: string, startingPrice: number, distance: number, vehicle: Vehicle, type: RideType, passengers: number, cargoWeight: number, cargoVolume: number): void {
    let id = this.findPossibleID();
    this.rides.push(new Ride(id, from, destination, startingPrice, distance, vehicle, type, passengers, cargoWeight, cargoVolume));
    this.vehiclesService.markInUse(vehicle.getId());
    this.save()
  }

  finishRide(id: number) {
    let index = this.rides.findIndex((ride => ride.getId() == id));
    this.rides[index].setEndTime(new Date());
    let vehicleId = this.rides[index].getVehicle().getId();
    this.vehiclesService.markNotInUse(vehicleId);
    this.vehiclesService.changeOdometerByValueById(vehicleId, this.rides[index].getDistance());
    this.save()
  }

  findPossibleID(): number {
    this.rides = this.getRides();
    let len = this.rides.length;
    if (len === 0) {
      return 0;
    }
    let ride = this.rides[len-1];
    console.warn("Last element in rides service:")
    console.log(ride);
    console.log("Found new id: " + ride.getId() + 1);
    return ride.getId() + 1;
  }

  save() {
    console.log("Saving:");
    console.log(this.rides);
    localStorage.setItem('rides', JSON.stringify(this.rides));
  }

  getRides(): Ride[] {
    let json = [];
    try {
      json = JSON.parse(localStorage.getItem('rides'))
    } catch (e) {
      console.error("Detected broken or empty rides data")
    }

    let rides: Ride[] = [];
    for (let index in json) {
      let ride = json[index]
      let vehicle = this.vehiclesService.parseVehicle(ride.vehicle);
      let startDate = new Date(ride.startTime);
      if (ride.endTime != undefined) {
        let endDate = new Date(ride.endTime)
        rides.push(new Ride(ride.id, ride.from, ride.destination, ride.startingPrice, ride.distance, vehicle, ride.type, ride.passengers, ride.cargoWeight, ride.cargoVolume, ride.price, startDate, endDate))
      } else {
        rides.push(new Ride(ride.id, ride.from, ride.destination, ride.startingPrice, ride.distance, vehicle, ride.type, ride.passengers, ride.cargoWeight, ride.cargoVolume, ride.price, startDate))
      }
    }
    rides.sort((a: Ride, b: Ride) => a.getId() - b.getId())
    this.rides = rides;
    return this.rides;
  }

  deleteAllRides() {
    for (let ride of this.rides) {
      let vehicleID = ride.getVehicle().getId();
      this.vehiclesService.markNotInUse(vehicleID);
    }
    this.rides = [];
    this.save();
  }

}
