import { VehicleOptions } from "./vehicleOptions";
import { VehicleType } from "./VehicleType";

export abstract class Vehicle {
    abstract canTransportPassangerFun(): boolean;
    abstract canTransportFreightFun(): boolean;
    abstract getTypeAsString(): string;
    private id: number;
    private make: string;
    private model: string;
    private type: VehicleType;
    private licensePlate: string;
    private gasUsage: number;
    private pricePerKM: number;
    private odometer: number;
    private canTransportPassanger: boolean;
    private canTransportFreight: boolean;
    private inUse: boolean;
    private vehicleOptions: VehicleOptions;


    constructor(id: number, make: string, model: string, licensePlate: string, gasUsae: number, pricePerKM: number, odometer: number, canTransportPassanger: boolean, canTransportFreight: boolean, type: VehicleType, vehicleOptions: VehicleOptions, inUse?: boolean) {
        this.id = id;
        this.make = make;
        this.model = model;
        this.licensePlate = licensePlate;
        this.gasUsage = gasUsae;
        this.pricePerKM = pricePerKM;
        this.odometer = odometer;
        this.canTransportPassanger = canTransportPassanger;
        this.canTransportFreight = canTransportFreight;
        this.type = type;
        if (inUse != undefined) {
            this.inUse = inUse;
        } else {
            this.inUse = false;
        }
        this.vehicleOptions = vehicleOptions;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getMake(): string {
        return this.make;
    }

    public setMake(make: string): void {
        this.make = make;
    }

    public getModel(): string {
        return this.model;
    }

    public setModel(model: string): void {
        this.model = model;
    }

    public getType(): VehicleType {
        return this.type;
    }

    public setType(type: VehicleType): void {
        this.type = type;
    }

    public getLicensePlate(): string {
        return this.licensePlate;
    }

    public setLicensePlate(licensePlate: string): void {
        this.licensePlate = licensePlate;
    }

    public getGasUsage(): number {
        return this.gasUsage;
    }

    public setGasUsage(gasUsage: number): void {
        this.gasUsage = gasUsage;
    }

    public getPricePerKM(): number {
        return this.pricePerKM;
    }

    public setPricePerKM(pricePerKM: number): void {
        this.pricePerKM = pricePerKM;
    }

    public getOdometer(): number {
        return this.odometer;
    }

    public setOdometer(odometer: number): void {
        this.odometer = odometer;
    }

    public isCanTransportPassanger(): boolean {
        return this.canTransportPassanger;
    }

    public setCanTransportPassanger(canTransportPassanger: boolean): void {
        this.canTransportPassanger = canTransportPassanger;
    }

    public isCanTransportFreight(): boolean {
        return this.canTransportFreight;
    }

    public setCanTransportFreight(canTransportFreight: boolean): void {
        this.canTransportFreight = canTransportFreight;
    }

    public isInUse(): boolean {
        return this.inUse;
    }

    public setInUse(inUse: boolean): void {
        this.inUse = inUse;
    }

    public getVehicleOptions(): VehicleOptions {
        return this.vehicleOptions;
    }

    public setVehicleOptions(vehicleOptions: VehicleOptions): void {
        this.vehicleOptions = vehicleOptions;
    }
    
}