import { Vehicle } from "./Vehicle";
import { VehicleType } from '../models/VehicleType';
import { VehicleOptions } from "./vehicleOptions";

export class Car extends Vehicle {
    canTransportFreightFun(): boolean {
        return false;
    }
    canTransportPassangerFun(): boolean {
        return true;
    }
    getTypeAsString(): string {
        return "Car"
    }
    constructor(id: number, make: string, model: string, licensePlate: string, gasUsae: number, pricePerKM: number, odometer: number, options: VehicleOptions, inUse?: boolean) {
        super(id, make, model, licensePlate, gasUsae, pricePerKM, odometer, true, false, VehicleType.Car, options, inUse);
        //this.maxPassangers = maxPassangers;
    }
}