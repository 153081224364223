<table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Type</th>
        <th scope="col">From</th>
        <th scope="col">Destination</th>
        <th scope="col" (click)="sortByDistance()"><button class="btn fw-bold btn-light">Distance</button></th>
        <th scope="col">Vehicle</th>
        <th scope="col">Content</th>
        <th scope="col">Price</th>
        <th scope="col" (click)="sortByStart()"><button class="btn fw-bold btn-light">Start</button></th>
        <th scope="col">Completed</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ride of rides">
        <th scope="row">{{ride.id}}</th>
        <td *ngIf="ride.type===0">Passenger</td>
        <td *ngIf="ride.type===1">Freight</td>
        <td>{{ride.from}}</td>
        <td>{{ride.destination}}</td>
        <td>{{ride.distance}} km</td>
        <td>{{ride.vehicle.make}} {{ride.vehicle.model}} ({{ride.vehicle.licensePlate}})</td>
        <td *ngIf="ride.type===0 && ride.passengers===1">1 person</td>
        <td *ngIf="ride.type===0 && ride.passengers > 1">{{ride.passengers}} people</td>
        <td *ngIf="ride.type===1">{{ride.cargoWeight}}kg/{{ride.cargoVolume}}m³</td>
        <td>{{ride.price.toFixed(2)}}€</td>
        <td><div class="ms-2">{{formatDate(ride.startTime)}}</div></td>
        <td *ngIf="ride.endTime">{{formatDate(ride.endTime)}}</td>
        <td *ngIf="!ride.endTime">
            <button type="button" class="btn btn-sm btn-primary ms-2" (click)="completeRide(ride.id)">Finish</button>
        </td>
      </tr>
    </tbody>
  </table>

<div class="d-flex justify-content-center">
  <button type="button" class="btn btn-sm btn-danger " (click)="deleteAllRides()">Delete all rides</button>
</div>