import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { editMode } from '../models/EditMode';
import { Vehicle } from '../models/Vehicle';
import { VehicleOptions } from '../models/vehicleOptions';
import { VehicleType } from '../models/VehicleType';
import { VehiclesService } from '../services/vehicles.service';

@Component({
  selector: 'app-vehicleaddform',
  templateUrl: './vehicleaddform.component.html',
  styleUrls: ['./vehicleaddform.component.css']
})

export class VehicleaddformComponent implements OnInit {
  currMode: string = "Add a new vehicle...";
  mode: editMode = editMode.no;
  id: string = "";
  validID: boolean = true;
  make: string = "";
  makeValid: boolean = false;
  model: string = "";
  modelValid: boolean = false;
  licensePlate: string = "";
  validLicensePlate: boolean = false;
  gasUsage: string = "10.0";
  validGasUsage: boolean = true;
  type: string = ""
  validType: boolean = false;
  price: string = "";
  validPrice: boolean = false;
  odometer: string = "";
  validOdometer: boolean = false;

  maxPassengers: string = "";
  validPassengers: boolean = false;
  showPassengers: boolean = false;
  maxCargoWeight: string = "";
  validMaxCargoWeight: boolean = false;
  showMaxCargoWeight: boolean = false;
  maxCargoVolume: string = "";
  validMaxCargoVolume: boolean = false;
  showMaxCargoVolume: boolean = false;
  

  constructor(private vehiclesService: VehiclesService) { }

  addVehicle(): void {
    if (this.finalValidation(editMode.no)) {
      let options: VehicleOptions = {
        maxPassengers: Number(this.maxPassengers),
        maxCargoVolume: Number(this.maxCargoVolume),
        maxCargoWeight: Number(this.maxCargoWeight)
      }
      this.vehiclesService.createVehicleBasedOnStringType(this.make, this.model, this.licensePlate, Number(this.gasUsage), Number(this.price), Number(this.odometer), this.type, options);
      window.location.reload();
    }
  }

  updateVehicle(): void {
    if (this.finalValidation(editMode.yes)) {
      let options: VehicleOptions = {
        maxPassengers: Number(this.maxPassengers),
        maxCargoVolume: Number(this.maxCargoVolume),
        maxCargoWeight: Number(this.maxCargoWeight)
      }
      this.vehiclesService.updateVehicle(Number(this.id), this.make, this.model, this.licensePlate, Number(this.gasUsage), Number(this.price), Number(this.odometer), options);
    }
  }

  fillEdit(vehicle: Vehicle): void {
    this.currMode = "Edit a vehicle...";
    this.mode = editMode.yes;
    this.id = vehicle.getId().toString();
    this.model = vehicle.getModel();
    this.make = vehicle.getMake();
    this.licensePlate = vehicle.getLicensePlate();
    this.gasUsage = vehicle.getGasUsage().toString();
    this.type = vehicle.getType().toString();
    this.price = vehicle.getPricePerKM().toString();
    this.odometer = vehicle.getOdometer().toString();
    this.maxPassengers = vehicle.getVehicleOptions().maxPassengers.toString();
    this.maxCargoVolume = vehicle.getVehicleOptions().maxCargoVolume.toString();
    this.maxCargoWeight = vehicle.getVehicleOptions().maxCargoWeight.toString();
    this.setProperVibility(vehicle.getType())
    this.finalValidation(editMode.yes);

    console.log(vehicle);
  }

  setProperVibility(type: any) {
    if (type === "Car" || type === VehicleType.Car) {
      this.showPassengers = true;
      this.showMaxCargoVolume = false;
      this.showMaxCargoWeight = false;
    }
    else if (type === "Van" || type === VehicleType.Van) {
      this.showPassengers = true;
      this.showMaxCargoVolume = true;
      this.showMaxCargoWeight = true;
    }
    else if (type === "Freighter" || type === VehicleType.Freighter) {
      this.showPassengers = false;
      this.showMaxCargoVolume = true;
      this.showMaxCargoWeight = true;
    }
  }

  addExampleVehicles(): void {
    this.vehiclesService.addExampleVehicles();
    window.location.reload();
  }

  ngOnInit(): void {
  }

  closeEdit(): void {
    window.location.reload()
  }

  validateMake(): boolean {
    if (this.make.length > 1 && this.make.length < 40) {
      this.makeValid = true;
      return true;
    }
    else {
      this.makeValid = false;
      return false;
    }
  }

  validateModel(): boolean {
    if (this.model.length > 1 && this.model.length < 40) {
      this.modelValid = true;
      return true;
    }
    else {
      this.modelValid = false;
    }
  }

  validateLicensePlate(): boolean {
    if (this.licensePlate.length > 6 && this.licensePlate.length < 10) {
      this.validLicensePlate = true;
      return true;
    }
    else {
      this.validLicensePlate = false;
      return false;
    }
  }

  validateGasUsage(): boolean {
    let num: number = 0;
    try {
      num = Number(this.gasUsage)
    } catch(e) {
      this.validGasUsage = false;
      return false;
    }
    if (num > 0 && num < 99) {
      this.validGasUsage = true;
      return true;
    }
    this.validGasUsage = false;
    return false
  }

  validateType(typeChanged?:boolean): boolean {
    if (this.type === "Car") {
      this.validType = true;
      this.setProperVibility(VehicleType.Car)
      if (typeChanged === true) {
        this.maxPassengers = "3";
        this.maxCargoVolume = "0";
        this.maxCargoWeight = "0";
      }
      this.validateOptions();
      return true;
    }
    else if (this.type === "Van") {
      this.validType = true;
      this.setProperVibility(VehicleType.Van)
      if (typeChanged === true) {
        this.maxPassengers = "6";
        this.maxCargoVolume = "2";
        this.maxCargoWeight = "500";
      }
      this.validateOptions();
      return true;
    }
    else if (this.type === "Freighter") {
      this.validType = true;
      this.setProperVibility(VehicleType.Freighter)
      if (typeChanged === true) {
        this.maxPassengers = "0";
        this.maxCargoVolume = "7";
        this.maxCargoWeight = "6000";
      }
      this.validateOptions();
      return true;
    }
    this.validType = false;
    return false;
  }

  validatePrice(): boolean {
    let num: number = 0;
    try {
      num = Number(this.price)
    } catch(e) {
      this.validPrice = false;
      return false;
    }
    if (num > 0 && num < 100) {
      this.validPrice = true;
      return true;
    }
    this.validPrice = false;
    return false
  }

  validateOdometer(): boolean {
    let num: number = 0;
    try {
      num = Number(this.odometer)
    } catch(e) {
      this.validOdometer = false;
      return false;
    }
    if (num > 0) {
      this.validOdometer = true;
      return true;
    }
    this.validOdometer = false;
    return false
  }

  validateOptions(): boolean {
    let success = true;
    if (!this.validateMaxPassengers()) {
      success = false;
    }
    if (!this.validateMaxCargoVolume()) {
      success = false;
    }
    if (!this.validateMaxCargoWeight()) {
      success = false;
    }
    return success;
  }

  validateMaxPassengers(): boolean {
    let num: number = 0;
    try {
      num = Number(this.maxPassengers)
    } catch(e) {
      this.validPassengers = false;
      return false;
    }
    if (num >= 0 && num < 100) {
      this.validPassengers = true;
      this.maxPassengers = parseInt(this.maxPassengers).toString();
      return true;
    }
    this.validPassengers = false;
    return false
  }

  validateMaxCargoWeight(): boolean {
    let num: number = 0;
    try {
      num = Number(this.maxCargoWeight)
    } catch(e) {
      this.validMaxCargoWeight = false;
      return false;
    }
    if (num >= 0 && num < 100000) {
      this.validMaxCargoWeight = true;
      return true;
    }
    this.validMaxCargoWeight = false;
    return false
  }

  validateMaxCargoVolume(): boolean {
    let num: number = 0;
    try {
      num = Number(this.maxCargoVolume)
    } catch(e) {
      this.validMaxCargoVolume = false;
      return false;
    }
    if (num >= 0 && num < 100000) {
      this.validMaxCargoVolume = true;
      return true;
    }
    this.validMaxCargoVolume = false;
    return false
  }


  // Each function called explcitly so the browser view updates and user gets to know his error.
  finalValidation(mode: editMode): boolean {
    let success = true;
    if (!this.validateMake()) {
      success = false;
    }
    if (!this.validateModel()) {
      success = false;
    }
    if (!this.validateLicensePlate()) {
      success = false;
    }
    if (!this.validateGasUsage()) {
      success = false;
    }
    if (!this.validateType()) {
      if (mode == editMode.no) {
        success = false;
      }
    }
    if (!this.validatePrice()) {
      success = false;
    }
    if (!this.validateOdometer()) {
      success = false;
    }
    if (!this.validateMaxPassengers()) {
      success = false;
    }
    if (!this.validateMaxCargoWeight()) {
      success = false;
    }
    if (!this.validateMaxCargoVolume()) {
      success = false;
    }
    return success;
  }

  refresh() {
    window.location.reload();
  }
}
