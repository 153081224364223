import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RidesComponent } from './rides/rides.component';
import { VehiclesComponent } from './vehicles/vehicles.component';

const routes: Routes = [
  {path: "", component: RidesComponent},
  {path: "rides", component: RidesComponent},
  {path: "vehicles", component: VehiclesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
