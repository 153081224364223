import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Ride } from '../models/Ride';
import { RideType } from '../models/RideType';
import { Vehicle } from '../models/Vehicle';
import { RidesService } from '../services/rides.service';
import { VehiclesService } from '../services/vehicles.service';

@Component({
  selector: 'app-rides',
  templateUrl: './rides.component.html',
  styleUrls: ['./rides.component.css']
})
export class RidesComponent implements OnInit {
  private vehicles: Vehicle[] = [];
  rides: Ride[] = [];

  constructor(private vehiclesService: VehiclesService, private ridesService: RidesService) { }

  ngOnInit(): void {
    this.vehicles = this.vehiclesService.getVehicles();
    //this.ridesService.createRide("WAW", "KTW", 2, 20, this.vehicles[0], RideType.Passenger, 2, 0, 0);
    //this.ridesService.createRide("KTW", "WAW", 2, 60, this.vehicles[1], RideType.Freight, 0, 30, 60);
    //this.ridesService.deleteAllRides();
    this.rides = this.ridesService.getRides();
    console.log("RidesComponent ngOnInit() rides:")
    console.log(this.rides);
    //this.ridesService.createRide("A", "B", 1, 1, this.vehicles[0], RideType.Passenger, 1, 0, 0);
  }

}
