import { Injectable, OnInit } from '@angular/core';
import { Vehicle } from '../models/Vehicle';
import { Car } from '../models/Car';
import { Van } from '../models/Van';
import { Freighter } from '../models/Freighter';
import { VehicleType } from '../models/VehicleType';
import { VehicleOptions } from '../models/vehicleOptions';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  private vehicles: Vehicle[] = [];

  constructor() { 
    this.getVehicles();
  }

  getVehicles(): Vehicle[] {
    let json = [];
    try {
      json = JSON.parse(localStorage.getItem('vehicles'))
    } catch (e) {
      console.error("Detected broken or empty vehicle data!")
    }
    
    let vehicles: Vehicle[] = [];
    for (let index in json) {
      let vehicle = json[index]
      if (vehicle.type === VehicleType.Car) {
        vehicles.push(new Car(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions, vehicle.inUse))
      }
      else if (vehicle.type === VehicleType.Van) {
        vehicles.push(new Van(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions, vehicle.inUse))
      }
      else if (vehicle.type === VehicleType.Freighter) {
        vehicles.push(new Freighter(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions, vehicle.inUse))
      }
    }
    vehicles.sort((a: Vehicle, b: Vehicle) => a.getId() - b.getId())
    // console.log("Vehicles from VehiclesService getVehicles():")
    // console.log(vehicles);
    this.vehicles = vehicles;
    return this.vehicles;
  }

  getVehicleByID(id: number): Vehicle {
    let index = this.vehicles.findIndex((veh => veh.getId() == id));
    return this.vehicles[index];
  }

  findUnUsedPassengerVehicle(passengers: number): Vehicle[] {
    let possibleVehicles = [];
    for (let vehicle of this.vehicles) {
      if (vehicle.canTransportPassangerFun() && !vehicle.isInUse() && vehicle.getVehicleOptions().maxPassengers >= passengers) {
        possibleVehicles.push(vehicle);
      }
    }
    return possibleVehicles;
  }

  findUnUsedCargoVehicle(cargoWeight: number, cargoVolume: number): Vehicle[] {
    let possibleVehicles = [];
    for (let vehicle of this.vehicles) {
      if (vehicle.canTransportFreightFun() && !vehicle.isInUse() && vehicle.getVehicleOptions().maxCargoWeight >= cargoWeight && vehicle.getVehicleOptions().maxCargoVolume >= cargoVolume) {
        possibleVehicles.push(vehicle);
      }
    }
    return possibleVehicles;
  }

  parseVehicle(vehicle): Vehicle {
    if (vehicle.type === VehicleType.Car) {
      return new Car(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions)
    }
    else if (vehicle.type === VehicleType.Van) {
      return new Van(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions)
    }
    else if (vehicle.type === VehicleType.Freighter) {
      return new Freighter(vehicle.id, vehicle.make, vehicle.model, vehicle.licensePlate, vehicle.gasUsage, vehicle.pricePerKM, vehicle.odometer, vehicle.vehicleOptions)
    }
    return null
  }

  createVehicleBasedOnStringType(make: string, model: string, licensePlate: string, gasUsage: number, pricePerKM: number, odometer: number, type: string, options: VehicleOptions): void {
    if (type === "Car") {
      this.createCar(make, model, licensePlate, gasUsage, pricePerKM, odometer, options);
    }
    else if (type === "Van") {
      this.createVan(make, model, licensePlate, gasUsage, pricePerKM, odometer, options);
    }
    else if (type === "Freighter") {
      this.createFreighter(make, model, licensePlate, gasUsage, pricePerKM, odometer, options);
    }
  }

  createCar(make: string, model: string, licensePlate: string, gasUsage: number, pricePerKM: number, odometer: number, options: VehicleOptions): void {
    let id = this.findPossibleID();
    this.vehicles.push(new Car(this.findPossibleID(), make, model, licensePlate, gasUsage, pricePerKM, odometer, options));
    console.log("Creating car...");
    this.save();
  }

  createVan(make: string, model: string, licensePlate: string, gasUsage: number, pricePerKM: number, odometer: number, options: VehicleOptions): void {
    let id = this.findPossibleID();
    this.vehicles.push(new Van(this.findPossibleID(), make, model, licensePlate, gasUsage, pricePerKM, odometer, options));
    console.log("Creating van...");
    this.save();
  }

  createFreighter(make: string, model: string, licensePlate: string, gasUsage: number, pricePerKM: number, odometer: number, options: VehicleOptions): void {
    let id = this.findPossibleID();
    this.vehicles.push(new Freighter(this.findPossibleID(), make, model, licensePlate, gasUsage, pricePerKM, odometer, options));
    console.log("Creating van...");
    this.save();
  }

  updateVehicle(id: number, make: string, model: string, licensePlate: string, gasUsage: number, pricePerKM: number, odometer: number, options: VehicleOptions) {
    let index = this.vehicles.findIndex((veh => veh.getId() == id));
    this.vehicles[index].setMake(make);
    this.vehicles[index].setModel(model);
    this.vehicles[index].setLicensePlate(licensePlate);
    this.vehicles[index].setGasUsage(gasUsage);
    this.vehicles[index].setPricePerKM(pricePerKM);
    this.vehicles[index].setOdometer(odometer);
    this.vehicles[index].setVehicleOptions(options);
    this.save();
  }

  markInUse(id: number): void {
    let index = this.vehicles.findIndex((veh => veh.getId() == id));
    this.vehicles[index].setInUse(true);
    this.save()
  }

  markNotInUse(id: number): void {
    let index = this.vehicles.findIndex((veh => veh.getId() == id));
    this.vehicles[index].setInUse(false);
    this.save()
  }

  changeOdometerByValueById(id: number, change: number) {
    let index = this.vehicles.findIndex((veh => veh.getId() == id));
    let newOdometerValue = this.vehicles[index].getOdometer() + change;
    this.vehicles[index].setOdometer(newOdometerValue)
    this.save()
  }

  addExampleVehicles(): void {
    this.createCar("Honda", "CR-V", "HSJ830P", 7.1, 0.9, 120032, {maxPassengers: 4, maxCargoVolume: 0, maxCargoWeight: 0})
    this.createVan("Kia", "Sedona", "FCK2341W", 9.2, 1.1, 260321, {maxPassengers: 6, maxCargoVolume: 2, maxCargoWeight: 500});
    this.createFreighter("Nissan", "Frontier", "HDW2313C", 11.3, 1.9, 190321, {maxPassengers: 0, maxCargoVolume: 4, maxCargoWeight: 1200})
    this.createCar("Hyundai ", "i10", "DFJH80W", 5.2, 0.7, 12032, {maxPassengers: 3, maxCargoVolume: 0, maxCargoWeight: 0})
    this.createVan("Honda ", "Odyssey", "DKJH321H", 9.1, 1.1, 26021, {maxPassengers: 6, maxCargoVolume: 2, maxCargoWeight: 500});
    this.createFreighter("DAF ", "2800 ATI DKSE", "GTF2137JP", 18.9, 3.25, 890021, {maxPassengers: 0, maxCargoVolume: 10, maxCargoWeight: 12000})
}

  deleteVehicleByID(id: number): void {
    this.vehicles.splice(id, 1);
    this.save();
  }

  deleteAllVehicles() {
    this.vehicles = [];
    this.save();
  }

  save() {
    console.log("Saving:");
    console.log(this.vehicles);
    localStorage.setItem('vehicles', JSON.stringify(this.vehicles));
  }

  findPossibleID(): number {
    let len = this.vehicles.length;
    if (len === 0) {
      return 0;
    }
    let vehicle = this.vehicles[len-1];
    console.log("Found new id: "+ vehicle.getId() + 1);
    return vehicle.getId() + 1;
  }
}
