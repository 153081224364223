import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Ride } from '../models/Ride';
import { RidesService } from '../services/rides.service';

@Component({
  selector: 'app-rideslist',
  templateUrl: './rideslist.component.html',
  styleUrls: ['./rideslist.component.css']
})
export class RideslistComponent implements OnInit {
  @Input() rides: Ride[]
  private sortingFlag: boolean = true;

  constructor(private ridesService: RidesService) { }

  ngOnInit(): void {
    this.sortByStart();
  }

  formatDate(date: Date): string {
    return moment(date).format("HH:mm")
  }

  completeRide(id: number): void {
    this.ridesService.finishRide(id);
  }

  deleteAllRides(): void {
    this.ridesService.deleteAllRides();
    window.location.reload();
  }

  sortByStart() {
    if (this.sortingFlag === true) {
      this.rides.sort((a: Ride, b: Ride) => b.getStartTime().getTime() - a.getStartTime().getTime());
      this.sortingFlag = false;
    } else {
      this.rides.sort((a: Ride, b: Ride) => a.getStartTime().getTime() - b.getStartTime().getTime());
      this.sortingFlag = true;
    }
  }

  sortByDistance() {
    if (this.sortingFlag === true) {
      this.rides.sort((a: Ride, b: Ride) => a.getDistance() - b.getDistance());
      this.sortingFlag = false;
    } else {
      this.rides.sort((a: Ride, b: Ride) => b.getDistance() - a.getDistance());
      this.sortingFlag = true;
    }
    
  }
}
