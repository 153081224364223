import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isHome: boolean = true;

  constructor(private location:Location) { }

  ngOnInit(): void {
    if (this.location.path() === "/vehicles") {
      this.isHome = false;
    }
  }

}
