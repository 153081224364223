import { Vehicle } from "./Vehicle";
import { VehicleType } from '../models/VehicleType';
import { VehicleOptions } from "./vehicleOptions";

export class Freighter extends Vehicle {
    canTransportFreightFun(): boolean {
        return true;
    }
    canTransportPassangerFun(): boolean {
        return false;
    }
    getTypeAsString(): string {
        return "Freighter"
    }
    constructor(id:number, make: string, model: string, licensePlate: string, gasUsae: number, pricePerKM: number, odometer: number, options: VehicleOptions, inUse?: boolean) {
        super(id, make, model, licensePlate, gasUsae, pricePerKM, odometer, false, true, VehicleType.Freighter, options, inUse);
    }
}