import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Vehicle } from '../models/Vehicle';
import { VehiclesService } from '../services/vehicles.service';

@Component({
  selector: 'app-vehiclelist',
  templateUrl: './vehiclelist.component.html',
  styleUrls: ['./vehiclelist.component.css'],
})
export class VehiclelistComponent implements OnInit {
  @Input() vehicles: Vehicle[];
  @Output() editEvent = new EventEmitter<Vehicle>();
  sortMode: boolean = true;

  constructor(private vehiclesService: VehiclesService) { }

  ngOnInit(): void {
    console.log("VehiclelistComponent received: ")
    console.log(this.vehicles)
  }

  deleteVehicleByID(id: number) {
    this.vehiclesService.deleteVehicleByID(id);
  }

  sort():void {
    if (this.sortMode === true) {
      this.vehicles.sort(this.compareByLicensePlateA);
      this.sortMode = false;
    }
    else {
      this.vehicles.sort(this.compareByLicensePlateB);
      this.sortMode = true;
    }

  }

  emitEdit(vehicle: Vehicle) {
    this.editEvent.emit(vehicle)
  }

  compareByLicensePlateA(a: Vehicle, b: Vehicle): number {
    return a.getLicensePlate().localeCompare(b.getLicensePlate());
  }

  compareByLicensePlateB(a: Vehicle, b: Vehicle): number {
    return b.getLicensePlate().localeCompare(a.getLicensePlate());
  }

  deleteAllVehicles(): void {
    this.vehiclesService.deleteAllVehicles();
    window.location.reload();
  }
  
}
