import { Component, OnInit } from '@angular/core';
import { RideType } from '../models/RideType';
import { Vehicle } from '../models/Vehicle';
import { RidesService } from '../services/rides.service';
import { VehiclesService } from '../services/vehicles.service';

@Component({
  selector: 'app-rideaddform',
  templateUrl: './rideaddform.component.html',
  styleUrls: ['./rideaddform.component.css']
})
export class RideaddformComponent implements OnInit {
  possibleVehicles: Vehicle[] = [];
  selectedVehicle: string;
  selectedVehicleId: number;
  selectedVehicleValid: boolean = false;
  from: string = "";
  fromValid: boolean = false;
  to: string = "";
  toValid: boolean = false
  distance: string = "";
  distanceValid: boolean = false;
  type: string = "";
  typeValid: boolean = false;
  basePrice: string = "";
  basePriceValid: boolean = false;

  passengers: string = "0";
  passenegrsValid: boolean = false;
  cargoWeight: string = "0";
  cargoWeightValid: boolean = false;
  cargoVolume: string = "0";
  cargoVolumeValid: boolean = false;

  calculatedPrice: number = 0;

  constructor(private vehiclesService: VehiclesService, private ridesService: RidesService) { }

  ngOnInit(): void {
  }

  findPassengerVehicle(): void {
    let passengers = Number(this.passengers);
    console.log("Looking for possible passenger cars...")
    this.possibleVehicles = this.vehiclesService.findUnUsedPassengerVehicle(passengers);
    console.log("Found:")
    console.log(this.possibleVehicles);
    this.validateVehicle();
  }

  findCargoVehicle(): void {
    let cargoWeight = Number(this.cargoWeight);
    let cargoVolume = Number(this.cargoVolume);
    console.log("Looking for possible passenger cars...")
    this.possibleVehicles = this.vehiclesService.findUnUsedCargoVehicle(cargoWeight, cargoVolume);
    console.log("Found:")
    console.log(this.possibleVehicles);
    this.validateVehicle();
  }

  validateFrom(): boolean {
    if (this.from.length === 3) {
      this.fromValid = true;
      return true;
    } else {
      this.fromValid = false;
      return false;
    }
  }

  validateTo(): boolean {
    if (this.to.length === 3) {
      this.toValid = true;
      return true;
    } else {
      this.toValid = false;
      return false;
    }
  }

  validateDistance(): boolean {
    let num: number = 0;
    try {
      num = Number(this.distance)
    } catch(e) {
      this.distanceValid = false;
      return false;
    }
    if (num > 0) {
      this.distanceValid = true;
      this.calculatePrice();
      return true;
    }
    this.distanceValid = false;
    return false
  }

  validateBasePrice(): boolean {
    let num: number = 0;
    try {
      num = Number(this.basePrice)
      if (isNaN(num)) {
        this.basePriceValid = false;
        return false;
      }
      this.basePriceValid = true;
      this.calculatePrice();
      return true;
    } catch(e) {
      this.basePriceValid = false;
      return false;
    }
  }

  validateType(typeHasChanged?: boolean): boolean {
    if (this.type === "Passenger transport") {
      this.typeValid = true;
      if (typeHasChanged) {
        this.passengers = "1";
        this.cargoWeight = "0";
        this.cargoVolume = "0";
      }
      this.validatePassengers();
      return true
    }
    else if (this.type === "Cargo transport") {
      this.typeValid = true;
      if (typeHasChanged) {
        this.passengers = "0";
        this.cargoWeight = "5.0";
        this.cargoVolume = "1.0";
      }
      this.validateCargoWeight();
      this.validateCargoVolume();
      return true;
    }
    this.typeValid = false;
    return false;
  }

  validatePassengers(): boolean {
    let num: number = 0;
    try {
      num = Number(this.passengers)
    } catch(e) {
      this.passenegrsValid = false;
      return false;
    }

    if (this.type === "Passenger transport") {
      if (num > 0) {
        this.passengers = parseInt(this.passengers).toString();
        this.passenegrsValid = true;
        this.findPassengerVehicle();
        return true;
      } else {
        this.passenegrsValid = false;
        return false;
      }
    } else {
      if (num === 0) {
        this.passenegrsValid = true;
        return true;
      }
    }
  }

  validateCargoWeight(): boolean {
    let num: number = 0;
    try {
      num = Number(this.cargoWeight)
    } catch(e) {
      this.cargoWeightValid = false;
      return false;
    }

    if (this.type === "Cargo transport") {
      if (num > 0) {
        this.cargoWeightValid = true;
        this.findCargoVehicle();
        return true;
      } else {
        this.cargoWeightValid = false;
        return false;
      }
    } else {
      if (num == 0) {
        this.cargoWeightValid = true;
        return true;
      }
    }
  }

  validateCargoVolume(): boolean {
    let num: number = 0;
    try {
      num = Number(this.cargoVolume)
    } catch(e) {
      this.cargoVolumeValid = false;
      return false;
    }

    if (this.type === "Cargo transport") {
      if (num > 0) {
        this.cargoVolumeValid = true;
        this.findCargoVehicle();
        return true;
      } else {
        this.cargoVolumeValid = false;
        return false;
      }
    } else {
      if (num == 0) {
        this.cargoVolumeValid = true;
        return true;
      }
    }
  }

  validateVehicle(): boolean {
    if (this.selectedVehicle != null && this.selectedVehicle.length > 2) {
      let idStr = this.selectedVehicle.substring(this.selectedVehicle.indexOf("(")+1,this.selectedVehicle.indexOf(")"));
      let id = null;
      try {
        id = Number(idStr);
      } catch (e) {
        this.selectedVehicleValid = false;
        return false;
      }
      this.selectedVehicleValid = true;
      this.selectedVehicleId = id;
      this.calculatePrice();
      return true;
    }
    this.selectedVehicleValid = false;
    return false;
  }

  //Make sure to call every validation to update interface
  finalValidation(): boolean {
    let success = true;
    if (!this.validateFrom()) {
      success = false;
    }
    if (!this.validateTo()) {
      success = false;
    }
    if (!this.validateDistance()) {
      success = false;
    }
    if (!this.validateType()) {
      success = false;
    }
    if (!this.validatePassengers()) {
      success = false;
    }
    if (!this.validateCargoWeight()) {
      success = false;
    }
    if (!this.validateCargoVolume()) {
      console.log("Failed here");
      success = false;
    }
    if (!this.validateVehicle()) {
      success = false;
    }
    if (!this.validateBasePrice()) {
      success = false;
    }
    return success;
  }

  createRide() {
    let vehicle: Vehicle = this.vehiclesService.getVehicleByID(this.selectedVehicleId);
    let rideType: RideType = null;
    if (this.type === "Passenger transport") {
      rideType = RideType.Passenger
    }
    else if (this.type === "Cargo transport") {
      rideType = RideType.Freight
    }
    if (this.finalValidation()) {
      this.ridesService.createRide(this.from, this.to, Number(this.basePrice), Number(this.distance), vehicle, rideType, Number(this.passengers), Number(this.cargoWeight), Number(this.cargoVolume));
    }
    this.refresh();
  }

  calculatePrice(): void {
    try {
    let vehicle: Vehicle = this.vehiclesService.getVehicleByID(this.selectedVehicleId);
    let calculatedPrice = vehicle.getPricePerKM()*Number(this.distance)+Number(this.basePrice);
    if (calculatedPrice < 0) {
      this.calculatedPrice = 0;
    } else {
      this.calculatedPrice = calculatedPrice;
    }
    } catch (e) {
      this.calculatedPrice = 0;
    }
  }

  refresh(): void {
    window.location.reload();
  }
}
;