<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
    <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
    </a>

    <ul class="nav nav-pills col-12 col-md-auto mb-2 justify-content-center mb-md-0 m-1">
      <li class="nav-item mx-1"><a href="/rides" class="nav-link px-2" [ngClass]="{'active': isHome, 'link-dark': !isHome}">Rides</a></li>
      <li class="nav-item mx-1"><a href="/vehicles" class="nav-link px-2" [ngClass]="{'active': !isHome, 'link-dark': isHome}">Vehicles</a></li>
    </ul>

    <div class="col-md-3 text-end">
    </div>
  </header>