<div class="container col-7">
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="row d-flex justify-content-between">
        <div class="col-2"></div>
        <div class="col-8"><h2 class="mb-4 fw-bold text-center">{{currMode}}</h2></div>
        <div class="col-2"><button *ngIf="mode" class="btn btn-info float-end" (click)="closeEdit()"><i class="bi bi-x-circle text-white"></i></button></div>
      </div>
      <form class="row g-3">
        <div class="col-md-1" *ngIf="mode">
        <fieldset disabled>
          <label for="validationID" class="form-label">ID</label>
          <input type="text" class="form-control" id="validationMake" [(ngModel)]="id" name="id">
          </fieldset>
        </div>
        <div [ngClass]="{'col-md-3': mode, 'col-md-4': !mode}">
          <label for="validationMake" class="form-label">Make</label>
          <input type="text" class="form-control" [ngClass]="{'is-valid': makeValid, 'is-invalid': !makeValid}" (change)="validateMake()" id="validationMake" required="" data-cip-id="validationMake" [(ngModel)]="make" name="make">
          <div *ngIf="makeValid" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!makeValid" class="invalid-feedback">
            Please provide valid maker.
          </div>
        </div>
        <div class="col-md-4">
          <label for="ValidationModel" class="form-label">Model</label>
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': modelValid, 'is-invalid': !modelValid}" (change)="validateModel()"  id="ValidationModel" required="" data-cip-id="ValidationModel" [(ngModel)]="model" name="model">
          <div *ngIf="modelValid" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!modelValid" class="invalid-feedback">
            Please provide valid model.
          </div>
        </div>
        <div class="col-md-4">
          <label for="ValidationPlate" class="form-label">License plate</label>
          <div class="input-group has-validation">
            <input type="text" class="form-control is-invalid" [ngClass]="{'is-valid': validLicensePlate, 'is-invalid': !validLicensePlate}" (change)="validateLicensePlate()" id="ValidationPlate" aria-describedby="inputGroupPrepend3" required="" data-cip-id="ValidationPlate" [(ngModel)]="licensePlate" name="licensePlate">
            <div *ngIf="validLicensePlate" class="valid-feedback">
              Looks good!
            </div>
            <div *ngIf="!validLicensePlate" class="invalid-feedback">
              Please enter license plate.
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="!mode">
          <label for="ValidationType" class="form-label">Type</label>
          <select class="form-select" [ngClass]="{'is-valid': validType, 'is-invalid': !validType}" (change)="validateType(true)" id="ValidationType" required="" data-cip-id="cIPJQ342845647" [(ngModel)]="type" name="type">
            <option selected="" disabled="" value="">Choose...</option>
            <option>Car</option>
            <option>Van</option>
            <option>Freighter</option>
          </select>
          <div *ngIf="validType" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validType" class="invalid-feedback">
            Please select a valid type.
          </div>
        </div>
        <div class="col-md-3" *ngIf="mode">
          <label for="ValidationType" class="form-label">Type</label>
          <fieldset disabled>
          <select class="form-select" [ngClass]="{'is-valid': validType, 'is-invalid': !validType}" (change)="validateType()" id="ValidationType" required="" data-cip-id="cIPJQ342845647">
            <option selected="" disabled="" value="">Can't edit</option>
          </select>
        </fieldset>
          <div *ngIf="validType" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validType" class="invalid-feedback">
            Please select a valid type.
          </div>
        </div>
        <div class="col-md-3">
          <label for="ValidationGasUsage" class="form-label">Gas usage</label>
          <div class="input-group mb-3">
          <input type="text" class="form-control is-invalid" [ngClass]="{'is-valid': validGasUsage, 'is-invalid': !validGasUsage}" (change)="validateGasUsage()" id="ValidationGasUsage" required="" data-cip-id="ValidationGasUsage" aria-describedby="basic-addon2" [(ngModel)]="gasUsage" name="gasUsage">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">l/100</small></span>
          
          <div *ngIf="validGasUsage" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validGasUsage" class="invalid-feedback">
            Please provide a valid gas usage.
          </div>
        </div>
        </div>

        <div class="col-md-3">
          <label for="ValidationPrice" class="form-label">Price</label>
          <div class="input-group mb-3">
          <input type="text" class="form-control" [ngClass]="{'is-valid': validPrice, 'is-invalid': !validPrice}" (change)="validatePrice()" id="ValidationPrice" required="" data-cip-id="ValidationPrice" [(ngModel)]="price" name="price">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">€/km</small></span>
          
          <div *ngIf="validPrice" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validPrice"class="invalid-feedback">
            Please provide a valid price.
          </div>
        </div>
        </div>
        <div class="col-md-3">
          <label for="ValidationOdometer" class="form-label">Odometer</label>
          <div class="input-group mb-3">
          <input type="text" class="form-control" [ngClass]="{'is-valid': validOdometer, 'is-invalid': !validOdometer}" (change)="validateOdometer()" id="ValidationOdometer" required="" data-cip-id="ValidationOdometer" [(ngModel)]="odometer" name="odometer">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">km</small></span>
          <div *ngIf="validOdometer" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validOdometer" class="invalid-feedback">
            Please provide a valid odometer reading.
          </div>
        </div>
        </div>
        <div class="col-md-4" *ngIf="showPassengers">
          <label for="ValidationPassengerLimit" class="form-label">Passenger limit</label>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validPassengers, 'is-invalid': !validPassengers}" (change)="validateMaxPassengers()"  id="ValidationPassengerLimit" required="" data-cip-id="ValidationPassengerLimit" [(ngModel)]="maxPassengers" name="maxPassengers">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">people</small></span>
        
          <div *ngIf="validPassengers" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validPassengers" class="invalid-feedback">
            Please provide valid passenger limit.
          </div>
        </div>
        </div>
        <div class="col-md-4" *ngIf="!showPassengers">
          <fieldset disabled>
          <label for="ValidationPassengerLimit" class="form-label">Passenger limit</label>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validPassengers, 'is-invalid': !validPassengers}" (change)="validateMaxPassengers()"  id="ValidationPassengerLimit" required="" data-cip-id="ValidationPassengerLimit" [(ngModel)]="maxPassengers" name="maxPassengers">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">people</small></span>
        
          <div *ngIf="validPassengers" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validPassengers" class="invalid-feedback">
            Please provide valid passenger limit.
          </div>
        </div>
        </fieldset>
        </div>
        <div class="col-md-4" *ngIf="showMaxCargoWeight">
          <label for="ValidationCargoWeight" class="form-label">Max cargo weight</label>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validMaxCargoWeight, 'is-invalid': !validMaxCargoWeight}" (change)="validateMaxCargoWeight()"  id="ValidationCargoWeight" required="" data-cip-id="ValidationCargoWeight" [(ngModel)]="maxCargoWeight" name="maxCargoWeight">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">kg</small></span>
          <div *ngIf="validMaxCargoWeight" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validMaxCargoWeight" class="invalid-feedback">
            Please provide valid cargo weight limit.
          </div>
        </div>
        </div>
        <div class="col-md-4" *ngIf="!showMaxCargoWeight">
          <label for="ValidationCargoWeight" class="form-label">Max cargo weight</label>
          <fieldset disabled>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validMaxCargoWeight, 'is-invalid': !validMaxCargoWeight}" (change)="validateMaxCargoWeight()"  id="ValidationCargoWeight" required="" data-cip-id="ValidationCargoWeight" [(ngModel)]="maxCargoWeight" name="maxCargoWeight">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">kg</small></span>
          <div *ngIf="validMaxCargoWeight" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validMaxCargoWeight" class="invalid-feedback">
            Please provide valid cargo weight limit.
          </div>
        </div>
        </fieldset>
        </div>
        <div class="col-md-4" *ngIf="showMaxCargoVolume">
          <label for="ValidationCargoVolume" class="form-label">Max cargo volume</label>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validMaxCargoVolume, 'is-invalid': !validMaxCargoVolume}" (change)="validateMaxCargoVolume()"  id="ValidationCargoVolume" required="" data-cip-id="ValidationCargoVolume" [(ngModel)]="maxCargoVolume" name="maxCargoVolume">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">m³</small></span>
          <div *ngIf="validMaxCargoVolume" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validMaxCargoVolume" class="invalid-feedback">
            Please provide valid cargo volume limit.
          </div>
        </div>
        </div>
        <div class="col-md-4" *ngIf="!showMaxCargoVolume">
          <label for="ValidationCargoVolume" class="form-label">Max cargo volume</label>
          <fieldset disabled>
          <div class="input-group mb-4">
          <input type="text" class="form-control is-valid" [ngClass]="{'is-valid': validMaxCargoVolume, 'is-invalid': !validMaxCargoVolume}" (change)="validateMaxCargoVolume()"  id="ValidationCargoVolume" required="" data-cip-id="ValidationCargoVolume" [(ngModel)]="maxCargoVolume" name="maxCargoVolume">
          <span class="input-group-text" id="basic-addon2"><small class="fw-light fs-7">m³</small></span>
          <div *ngIf="validMaxCargoVolume" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="!validMaxCargoVolume" class="invalid-feedback">
            Please provide valid cargo volume limit.
          </div>
        </div>
      </fieldset>
        </div>
      </form>
      <br>
      <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="col-4"><button type="button" class="btn btn-success" (click)="refresh()">Clear</button></div>
        <div class="col-4" *ngIf="mode"><button type="button" class="btn btn-primary" (click)="updateVehicle()">Submit</button></div>
        <div class="col-4 text-center" *ngIf="!mode"><button type="button" class="btn btn-primary" (click)="addVehicle()">Submit</button></div>
        <div class="col-4 text-end"><button type="button" class="btn btn-secondary" (click)="addExampleVehicles()">Add exmaples</button></div>
      </div>
      </div>
    </div>
  </div>

  <div>
    <div class="bd-example">
    
    </div>
  </div>